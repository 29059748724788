<template>
  <v-form
    id="reset-password-dialog-form"
    ref="form"
    @submit.prevent="handleForm"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.password"
            validate-on-blur
            :label="$trans('password')"
            :rules="[rules.required, rules.minChars(8)]"
            :append-icon="showPassword ? '$eye-on' : '$eye-off'"
            :type="showPassword ? 'text' : 'password'"
            outlined
            class="password"
            prepend-icon="$password"
            color="secondary"
            autocomplete="new-password"
            :disabled="loading"
            @click:append="showPassword = !showPassword"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" class="sign_up-form__description">
          <v-alert type="info" class="sign_up-form__description--alert">
            {{ $trans("reset_password_text_hint") }}

            <v-icon slot="prepend" color="blue-light-2" class="info-icon">
              $info
            </v-icon>
          </v-alert>
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            color="primary"
            type="submit"
            form="reset-password-dialog-form"
            height="56px"
            :loading="loading"
            :disabled="loading"
          >
            {{ $trans("reset_password_button") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import {
  required,
  minChars,
} from "@/lib/calendesk-js-library/forms/validators";
import dialogTypes from "../dialogTypes";
import log from "@/lib/calendesk-js-library/tools/log";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ResetPasswordDialogForm",
  mixins: [sharedActions],
  data: () => ({
    showPassword: false,
    form: {
      password: null,
    },
    rules: {
      required,
      minChars,
    },
    error: null,
    loading: false,
  }),
  methods: {
    ...mapActions({
      resetPassword: "auth/resetPassword",
    }),
    handleForm() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.$emit("loading", true);

      this.resetPassword({
        password: this.form.password,
        token: this.getDialog.data.token,
      })
        .then(() => {
          this.$emit("loading", false);
          this.loading = false;
          this.openDialog({
            type: dialogTypes.CONFIRM_PASSWORD_CHANGED,
            title: this.$trans("reset_password_success_title"),
          });
        })
        .catch((error) => {
          log.error("Reset Password Error Occurred", error);
          this.error = this.$trans("reset_password_fail_text");
        })
        .finally(() => {
          this.loading = false;
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.field-icon {
  width: 40px;
  justify-content: start;
  color: var(--v-success-base);
}

.error--text {
  .field-icon {
    color: var(--v-error-base);
  }
}

.info-icon {
  margin-right: 10px;
  align-self: flex-start;
}

.sign_up-form__description--alert {
  background: var(--v-accent-base) !important;
  color: #000 !important;
}
</style>
